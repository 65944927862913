import PublicTableWithCalculator from 'components/PublicTableWithCalculatorV2';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { BlockComponentProps } from 'strapi/types/utils';
import { transformStrapiDataToTable } from 'strapi/utils/transformStrapiDataToTable';

const NoSSRTable = dynamic(() => import('./components/Table'), {
  ssr: false,
});

const Table = ({
  block,
  onError,
}: BlockComponentProps<'marketing-website.table'>) => {
  if (!block.table?.data) {
    return onError('The table data is empty');
  }

  const { anchor } = block;

  const {
    attributes,
    attributes: { uid },
  } = block.table.data;

  const tableProps = transformStrapiDataToTable(attributes);

  if (uid.endsWith('public-health')) {
    return (
      <div id={anchor}>
        <PublicTableWithCalculator {...tableProps} stickyHeaderTopOffset={60} />
      </div>
    );
  }

  return (
    <div className="p-body" id={anchor}>
      <NoSSRTable
        {...tableProps}
        stickyHeaderTopOffset={60}
        imageComponent={(args) => <Image {...args} height={args.width} />}
      />
    </div>
  );
};

export default Table;
