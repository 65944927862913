import { TableData, TableProps } from '@popsure/dirty-swan';
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import { ReactNode } from 'react';
import StrapiRichTextComponent from 'strapi/components/StrapiRichText';
import { APIResponseData, GetDynamicZoneValues } from 'strapi/types/utils';

export const transformStrapiDataToTable = (
  table: APIResponseData<'api::table.table'>['attributes']
): TableProps => {
  let tableData: TableData = [
    {
      section: {
        title: '',
      },
      rows: [],
    },
  ];

  table.tableData.forEach((section, index) => {
    if (section.__component === 'table.table-section') {
      const sectionData = {
        section: {
          title: section.title || '',
          icon: section.icon?.data?.attributes.url,
        },
        rows: [],
      };

      if (index === 0) {
        tableData = [sectionData];
      } else {
        tableData.push(sectionData);
      }
    } else {
      const headerCell = formatHeaderCell(section);
      const formattedRow = formatRow(section);
      tableData[tableData.length - 1].rows.push([headerCell, ...formattedRow]);
    }
  });

  return {
    title: table.title || '',
    collapsibleSections: Boolean(table.collapsibleSections),
    hideDetails: Boolean(table.hideDetails),
    tableData,
  };
};

const formatHeaderCell = (
  section: APIResponseData<'api::table.table'>['attributes']['tableData'][number]
) => {
  switch (section.__component) {
    case 'table.table-row-card': {
      return formatCardRowHeader(section);
    }
    case 'table.table-section': {
      throw Error('Table section');
    }
    default: {
      return formatDefaultRowHeader(section);
    }
  }
};

const formatRow = (
  section: APIResponseData<'api::table.table'>['attributes']['tableData'][number]
) => {
  switch (section.__component) {
    case 'table.table-row-default':
      return formatDefaultRow(section);

    case 'table.table-row-with-cta':
      return formatCTARow(section);

    case 'table.table-row-button':
      return formatButtonRow(section);

    case 'table.table-row-card':
      return [];

    default:
      throw Error(`Unknown component type ${section.__component}`);
  }
};

// TODO: this will not be compatible with data-sync, as it returns ReactNode
//       instead of a markdown string. Discuss this with Diogo and Kim
const renderRichText = (
  richText: RootNode[] | undefined
): ReactNode | undefined => {
  if (!richText || !richText.length) {
    return undefined;
  }

  return StrapiRichTextComponent({ richText });
};

const formatDefaultRow = (
  section: GetDynamicZoneValues<'table.table-row-default'>
) =>
  section.cells.map((cell, rowIndex) => ({
    cellId: `defaultRow_${section.id}_${rowIndex}`,
    text: cell.text || undefined,
    description: cell.description || undefined,
    modalTitle: cell?.modalTitle || undefined,
    modalContent: renderRichText(cell.modalContent),
    checkmarkValue: cell.booleanIcon ? cell.booleanIcon === 'Yes' : undefined,
    fontVariant: cell.fontVariation === 'Price' ? 'PRICE' : undefined,
    hideProgressBar: Boolean(cell.hideProgressBar),
    rating: cell.rating
      ? {
          value: cell.rating,
          type: cell.ratingIcon === 'Star' ? 'star' : 'zap',
        }
      : undefined,
  }));

const formatCTARow = (
  section: GetDynamicZoneValues<'table.table-row-with-cta'>
) =>
  section.cells.map((cell, rowIndex) => ({
    cellId: `ctaRow_${section.id}_${rowIndex}`,
    type: 'CTA',
    title: cell.text,
    price: cell.description,
    grey: cell.greyStyle,
    narrow: cell.isNarrow,
    buttonCaption: cell.buttonCaption,
    icon: cell.icon?.data?.attributes.url,
    href: cell.ctaLink,
  }));

const formatButtonRow = (
  section: GetDynamicZoneValues<'table.table-row-button'>
) =>
  section.cells.map((cell, index) => ({
    cellId: `buttonRow_${section.id}_${index}`,
    type: 'BUTTON',
    buttonCaption: cell.text,
    price: cell.description,
    disabled: true,
  }));

const formatCardRowHeader = (
  section: GetDynamicZoneValues<'table.table-row-card'>
) => {
  const { id, text, description, columnsToOccupy, icon, link } = section;
  return {
    cellId: `cardRow_${id}_header`,
    type: 'CARD',
    title: text,
    description,
    colSpan: columnsToOccupy || 1,
    icon: icon?.data?.attributes.url,
    href: link?.url,
  };
};

const formatDefaultRowHeader = (
  section:
    | GetDynamicZoneValues<'table.table-row-default'>
    | GetDynamicZoneValues<'table.table-row-button'>
    | GetDynamicZoneValues<'table.table-row-with-cta'>
) => {
  return {
    cellId: `${section.id}_header`,
    text: section.header?.text,
    description: section.header?.description,
    modalTitle: section.header?.modalTitle,
    modalContent: renderRichText(section.header?.modalContent),
  };
};
