import { ArrowRightIcon, Card, TableProps } from '@popsure/dirty-swan';
import Markdown from 'components/markdown';
import PriceCalculatorContainer from 'components/priceCalculatorContainer';
import { isEligibleForPrivate } from 'components/PublicTableWithCalculator/lib/isEligibleForPrivate';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { goToSection } from 'util/goToSection';

import {
  CalculatorVariables,
  PublicQuoteWidget,
} from '../PublicTableWithCalculator/publicQuoteWidget';
import styles from '../PublicTableWithCalculator/style.module.scss';
import { useCellReplacements } from './hooks/useCellReplacements';

const NoSSRTable = dynamic(() => import('./components/Table'), {
  ssr: false,
});
const PublicTableWithCalculator = (props: TableProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const widgetRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [calculatorVariables, setCalculatorVariables] = useState<
    Partial<CalculatorVariables>
  >({});

  const hasBKKFirmus = locale === 'de-de';
  const { cellReplacements, defaultPrice } = useCellReplacements({
    options: calculatorVariables,
    handleOnCalculateClick: () => goToSection(widgetRef, 64),
    hasBKKFirmus,
  });

  const handleCalculatorChange = (
    key: keyof CalculatorVariables,
    value?: CalculatorVariables[keyof CalculatorVariables]
  ) =>
    setCalculatorVariables({
      ...calculatorVariables,
      [key]: value,
    });

  const eligibleForPrivate = isEligibleForPrivate({
    age: calculatorVariables.age,
    employmentStatus: calculatorVariables?.employmentStatus,
    income: calculatorVariables.annualIncome,
  });

  return (
    <>
      <article className={styles.calculator} ref={widgetRef}>
        <div className="p-body">
          <PriceCalculatorContainer
            title={t('publichealth:section.calculator.title')}
            hasBackgroundColor
            additionalInfo={
              <AnimateHeight
                duration={300}
                height={eligibleForPrivate ? 'auto' : 0}
              >
                <Card
                  as="a"
                  icon={
                    <Image
                      src="/static/icons/insurances/private-health.svg"
                      alt={t(
                        'publichealth:section.calculator.privathealthcta.image.alt'
                      )}
                      width={48}
                      height={48}
                    />
                  }
                  href="/health-insurance/private"
                  density="compact"
                  classNames={{
                    buttonWrapper: 'mt24',
                    icon: 'p16',
                    title: 'd-flex ai-center jc-between',
                  }}
                  title={
                    <>
                      {t(
                        'publichealth:section.calculator.privathealthcta.title'
                      )}

                      <ArrowRightIcon color="primary-500" size={20} />
                    </>
                  }
                  titleVariant="medium"
                  description={t(
                    'publichealth:section.calculator.privathealthcta.description'
                  )}
                  actionIcon={null}
                />
              </AnimateHeight>
            }
          >
            <PublicQuoteWidget
              variables={calculatorVariables}
              onChange={handleCalculatorChange}
              total={defaultPrice}
            />
          </PriceCalculatorContainer>
        </div>
      </article>
      <section className={styles.table} ref={tableRef}>
        <NoSSRTable
          {...props}
          cellReplacements={cellReplacements as TableProps['cellReplacements']}
          imageComponent={(args) => <Image {...args} height={args.width} />}
          hideColumns={hasBKKFirmus ? [4] : [5]}
          modalContentRenderer={(content) =>
            typeof content !== 'string' ? (
              content
            ) : (
              <Markdown openLinksInNewTab>{String(content)}</Markdown>
            )
          }
          textOverrides={{
            showDetails: t('publichealth:section.table.showDetails'),
            hideDetails: t('publichealth:section.table.hideDetails'),
          }}
        />
      </section>
    </>
  );
};

export default PublicTableWithCalculator;
